/**
 * parseBoolean taking a string 'true'/'false' and convert it to boolean
 */
const parseBoolean = (value: string | undefined): boolean => {
  if (value?.toLowerCase() === 'true') {
    return true
  } else if (value?.toLowerCase() === 'false') {
    return false
  } else {
    throw new Error(`Invalid value: ${value}. Expected 'true' or 'false'.`)
  }
}

export default parseBoolean
