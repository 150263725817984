import { notFound } from 'next/navigation'

import {
  ryderShareAuthenticatedPages,
  operationalAuthenticatedPages,
  sysadminAuthenticatedPages,
  tmCustomerAuthenticatedPages,
  DEFAULT_OPERATOR_HOME_PAGE,
} from '~/app/constants'

import { CustomerType, HomePage, UserRole } from '~/__generated_types__/globalTypes'

import { Page } from '~/app/types'

const getAvailablePages = (
  role: UserRole,
  customerType: CustomerType | null | undefined
): Array<Page> => {
  switch (role) {
    case UserRole.Operator:
      return [...operationalAuthenticatedPages]
    case UserRole.Customer:
      if (customerType === CustomerType.Tm) {
        return [...tmCustomerAuthenticatedPages]
      }

      return [...ryderShareAuthenticatedPages]
    case UserRole.Sysadmin:
      return [...operationalAuthenticatedPages, ...sysadminAuthenticatedPages]
    default:
      return []
  }
}

const getCurrentPage = (pathname: string, availablePages: Array<Page>): Page | null => {
  return (
    availablePages.find((page) => {
      const pathPattern = page.path.replace(/:\w+/g, '\\d+')
      const regex = new RegExp(`^${pathPattern}$`)

      if (regex.test(pathname) && page.isNavigable) {
        return page
      }
    }) ?? null
  )
}

const getOperatorHomePagePath = (homePage?: HomePage | null): string => {
  const page = homePage ?? DEFAULT_OPERATOR_HOME_PAGE

  switch (page) {
    case HomePage.DriverVisibility:
      return '/visibility'
    case HomePage.Loadboard:
      return '/loadboard'
    default:
      notFound()
  }
}

export { getAvailablePages, getCurrentPage, getOperatorHomePagePath }
