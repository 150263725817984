import * as Sentry from '@sentry/browser'

import parseBoolean from '~/helpers/parseBoolean'

export const verifyFeatureFlag = (featureFlag: string | undefined): boolean => {
  try {
    return parseBoolean(featureFlag)
  } catch (e) {
    Sentry.addBreadcrumb({
      message: `Could not parse ${featureFlag}`,
    })
    Sentry.captureException(e)
  }

  return false
}
