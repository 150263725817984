'use client'
import { notFound, redirect } from 'next/navigation'
import { memo } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { getOperatorHomePagePath } from '~/app/helpers'

import { useAuthenticatedUser } from '~/hooks/useAuthentication'

import { CustomerLoadBasePath, ShipmentBasePath } from '~/constants/urls'

import { CustomerType, UserRole } from '~/__generated_types__/globalTypes'

const RootPage = () => {
  const [user] = useAuthenticatedUser()
  const { showHomePageSetting } = useFlags()

  switch (user.role) {
    case UserRole.Operator:
    case UserRole.Sysadmin:
      showHomePageSetting
        ? redirect(getOperatorHomePagePath(user.settings.homePage))
        : redirect('/visibility')
    case UserRole.Customer:
      user.customer?.type && user.customer.type === CustomerType.Tm
        ? redirect(ShipmentBasePath)
        : redirect(CustomerLoadBasePath)
    default:
      notFound()
  }
}

export default memo(RootPage)
